// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-devlinks-js": () => import("/opt/build/repo/src/templates/devlinks.js" /* webpackChunkName: "component---src-templates-devlinks-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-about-mdx": () => import("/opt/build/repo/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-rants-angular-bad-parts-mdx": () => import("/opt/build/repo/src/pages/rants/angular-bad-parts.mdx" /* webpackChunkName: "component---src-pages-rants-angular-bad-parts-mdx" */),
  "component---src-pages-codereviews-mdx": () => import("/opt/build/repo/src/pages/codereviews.mdx" /* webpackChunkName: "component---src-pages-codereviews-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

